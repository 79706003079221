import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import moment from "moment-timezone";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import HandbookModule from "@/store/module/shared/HandbookModule";
import { PhoneMask } from "@/helpers/PhoneMask";
import UserModule from "@/store/module/user/UserModule";
import ApproveDialog from "@/components/shared/Approve.vue";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
let BasicInfoForm = class BasicInfoForm extends Vue {
    constructor() {
        super(...arguments);
        this.pickedRegion = null;
        this.name = {
            ref: "name",
            type: "input",
            label: "Имя",
            value: "",
            placeholder: "Роман",
            onInput: (value) => {
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.surname = {
            ref: "surname",
            type: "input",
            label: "Фамилия",
            value: "",
            placeholder: "Марков",
            onInput: (value) => {
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.patronymic = {
            ref: "patronymic",
            type: "input",
            label: "Отчество",
            value: "",
            placeholder: "Алексеевич",
            onInput: (value) => {
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.birthdate = {
            ref: "birthdate",
            type: "datepicker",
            label: "Дата рождения",
            value: "",
            isOpened: false,
            onInput: (value) => {
                this.birthdate.isOpened = !this.birthdate.isOpened;
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.gender = {
            ref: "gender",
            type: "select",
            label: "Пол",
            value: "male",
            items: () => [
                { label: "Мужской", value: "male" },
                { label: "Женский", value: "female" },
            ],
            isMultiple: false,
            onInput: (value) => {
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.avatar = {
            ref: "avatar",
            type: "fileupload",
            icon: "mdi-camera",
            label: "",
            placeholder: "Загрузите фото в формате JPEG, PNG, BMP размером 35х45 мм 300 dpi",
            value: "",
            accept: "image/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("avatar", file);
                const response = await this.$store.dispatch(UserModule.types.actions.UPLOAD_AVATAR, { fd });
                this.avatar.value = response.filename;
                this.pushChangeEvent();
            },
            rules: [Validation.required],
            showOnlyForSuperAuth: false,
            show: true,
        };
        this.phone = {
            ref: "phone",
            type: "phone",
            label: "Телефон",
            value: "",
            placeholder: PhoneMask.getPlaceholderByCountry("ru"),
            mask: PhoneMask.getMaskByPlaceholder("", PhoneMask.getPlaceholderByCountry("ru")),
            onInput: () => {
                this.pushChangeEvent();
            },
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.region = {
            ref: "region",
            type: "select",
            label: "Регион",
            value: "",
            items: () => this.regions,
            onInput: (value, manual = false) => {
                const id = parseInt(value, 10);
                this.pickedRegion = id;
                this.city.disabled = false;
                if (manual)
                    return;
                this.pushChangeEvent();
            },
            isMultiple: false,
            noData: "Список доступных регионов пуст",
            showOnlyForSuperAuth: true,
            disabled: false,
            rules: [],
        };
        this.city = {
            ref: "city",
            type: "select",
            label: "Город",
            value: "",
            items: () => this.citiesByRegion,
            onInput: (value) => {
                this.pushChangeEvent();
            },
            isMultiple: false,
            noData: "Список городов для выбранного региона пуст",
            showOnlyForSuperAuth: false,
            disabled: true,
            rules: [],
        };
        this.inNationalTeamSinceYear = {
            ref: "shared-inNationalTeamSinceYear",
            type: "input",
            label: "Год, с которого состоите в сборной",
            value: "",
            placeholder: "0000",
            showOnlyForSuperAuth: false,
            rules: [],
            show: true,
            mask: "####",
            onInput: () => {
                this.pushChangeEvent();
            },
        };
        this.sportsCategory = {
            ref: "sportsCategory",
            type: "select",
            label: "Спортивный разряд",
            value: "",
            items: () => this.sportsCategories,
            onInput: () => { },
            isMultiple: false,
            noData: "",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.baseFields = [
            this.surname,
            this.name,
            this.patronymic,
            this.birthdate,
            this.gender,
            this.sportsCategory,
            this.inNationalTeamSinceYear,
        ];
        this.contactsField = [this.phone];
        this.addressFields = [this.region, this.city];
        this.phoneCountrySelect = {
            defaultCountry: "ru",
            dialCode: "+7",
            onlyCountries: ["ru", "az", "am", "by", "kz", "md", "tj", "uz", "ua"],
            dynamicPlaceholder: true,
        };
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    get cities() {
        return this.$store.getters[HandbookModule.types.getters.CITIES];
    }
    get citiesByRegion() {
        if (this.pickedRegion === null)
            return [];
        return this.cities
            .map((item) => (item.regionId === this.pickedRegion ? item : undefined))
            .filter((item) => item !== undefined);
    }
    get regions() {
        return this.$store.getters[HandbookModule.types.getters.REGIONS];
    }
    get sportsCategories() {
        const items = this.$store.getters[HandbookModule.types.getters.SPORTS_CATEGORY].map((item) => ({
            label: item.category,
            value: item.id,
        }));
        items.unshift({ label: "", value: 0 });
        return items;
    }
    get showDefaultAvatar() {
        return !this.avatar.value && (!this.user || (this.user && !this.user.avatar));
    }
    get defaultAvatar() {
        if (!this.user)
            return require("@/assets/default-avatar.jpg");
        return `${IMAGE_SERVER_URL}/public/user-placeholder-${this.user.gender}.jpg`;
    }
    get uploadedAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.avatar.value}`;
    }
    onPhoneCountryChange(val) {
        this.phoneCountrySelect.dialCode = val.dialCode;
        this.phone.placeholder = PhoneMask.getPlaceholderByCountry(val.iso2.toLowerCase());
        this.phone.mask = PhoneMask.getMaskByPlaceholder(``, this.phone.placeholder);
        this.phone.value.replace(/\+[0-9]+/, "");
    }
    setTestFormData() {
        this.name.value = "Сергей";
        this.surname.value = "Павлович";
        this.patronymic.value = "Александрович";
        this.birthdate.value = moment().tz("Europe/Moscow").format("YYYY-MM-DD");
        this.gender.value = "male";
        this.avatar.value = "";
        this.phone.value = "(931) 231-23-23";
        this.region.value = 85;
        this.region.onInput(85);
        this.inNationalTeamSinceYear.value = "2005";
    }
    setFormData() {
        this.name.value = this.user.name;
        this.surname.value = this.user.surname;
        this.patronymic.value = this.user.patronymic;
        this.birthdate.value = moment(this.user.birthdate).format("YYYY-MM-DD");
        this.gender.value = this.user.gender;
        this.avatar.value = this.user.avatar;
        this.phone.value = this.user.phone ? this.user.phone.replace(/\+[0-9]+/, "") : "";
        if (this.user.address && !!this.user.address.regionId) {
            this.region.value = this.user.address.regionId;
            this.region.onInput(this.region.value, true);
            if (!!this.user.address.cityId) {
                this.city.value = this.user.address.cityId;
            }
        }
        if (this.user.profile && this.user.profile.inNationalTeamSinceYear) {
            this.inNationalTeamSinceYear.value = String(this.user.profile.inNationalTeamSinceYear);
        }
        if (this.user.sportsCategory && this.user.sportsCategory.sportsCategoryId) {
            this.sportsCategory.value = this.user.sportsCategory.sportsCategoryId;
        }
    }
    isFormValid() {
        const fields = this.baseFields.concat(this.contactsField).concat(this.addressFields);
        if (Validation.validateWithView(fields, this.$refs)) {
            return false;
        }
        else {
            return true;
        }
    }
    pushChangeEvent() {
        this.$emit("form-changed", {
            name: "BasicInfoForm",
            type: "main",
            payload: UserUpdateRequestDTO.createMainFromInput({
                name: this.name.value,
                surname: this.surname.value,
                patronymic: this.patronymic.value,
                birthdate: this.birthdate.value,
                gender: this.gender.value,
                phone: `+${this.phoneCountrySelect.dialCode.trim()} ${this.phone.value.trim()}`,
                avatar: this.avatar.value,
                address: {
                    regionId: this.region.value,
                    cityId: this.city.value,
                },
                inNationalTeamSinceYear: this.inNationalTeamSinceYear.value,
                sportsCategoryId: parseInt(String(this.sportsCategory.value), 10),
            }),
            isValid: this.isFormValid(),
        });
    }
    bindMock() {
        const key = "main";
        //@ts-ignore
        if (!window.profileMock) {
            //@ts-ignore
            window.profileMock = {
                [key]: this.setTestFormData,
            };
        }
        else {
            //@ts-ignore
            window.profileMock[key] = this.setTestFormData;
        }
    }
    async saveAvatar() {
        if (!this.avatar.value)
            return;
        if (this.user.avatar === this.avatar.value) {
            return this.$notify({
                title: "Не обнаружено изменений в данных",
                text: "Для успешной отравки заявки - внесите изменения",
                type: "warning",
                duration: 3000,
            });
        }
        await this.$store.dispatch(UserModule.types.actions.UPDATE_REQUEST, {
            request: new UserUpdateRequestDTO({
                main: UserUpdateRequestDTO.createMainFromInput({
                    name: this.user.name,
                    surname: this.user.surname,
                    patronymic: this.user.patronymic,
                    birthdate: this.user.birthdate,
                    gender: this.user.gender,
                    phone: `+${this.phoneCountrySelect.dialCode.trim()} ${this.phone.value.trim()}`,
                    avatar: this.avatar.value,
                    address: {
                        regionId: this.region.value,
                        cityId: this.city.value,
                    },
                    inNationalTeamSinceYear: this.inNationalTeamSinceYear.value,
                    sportsCategoryId: parseInt(String(this.sportsCategory.value), 10),
                }),
            }, null, []),
        });
        this.$notify({
            title: "Заявка на обновление профиля успешно отправлена!",
            type: "success",
            duration: 3000,
        });
    }
    async mounted() {
        await this.$store.dispatch(UserModule.types.actions.WAIT_FOR_USER_LOADING);
        this.setFormData();
        this.pushChangeEvent();
    }
};
BasicInfoForm = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
        directives: {
            mask,
        },
    })
], BasicInfoForm);
export default BasicInfoForm;

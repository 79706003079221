import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
// import { Prop, Model } from "vue-property-decorator"
// import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest"
// import moment from "moment-timezone"
import UserModule from "@/store/module/user/UserModule";
import BasicInfoForm from "./forms/BasicInfoForm.vue";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
import PassportForm from "./forms/PassportForm.vue";
import EquipForm from "./forms/EquipForm.vue";
import EmploymentForm from "./forms/EmploymentForm.vue";
import LivingForm from "./forms/LivingForm.vue";
import SocialForm from "./forms/SocialForm.vue";
import AboardForm from "./forms/AboardForm.vue";
import FamilyStatusForm from "./forms/FamilyStatusForm.vue";
import ParentsForm from "./forms/ParentsForm.vue";
import SportsmanForm from "./forms/SportsmanForm.vue";
import CoachForm from "./forms/CoachForm.vue";
import JudgeForm from "./forms/JudgeForm.vue";
import Waiter from "@/helpers/Waiter";
import HandbookModule from "@/store/module/shared/HandbookModule";
let UserProfile = class UserProfile extends Vue {
    constructor() {
        super(...arguments);
        this.updateRequestRaw = UserUpdateRequestDTO.emptyRaw();
        this.expansionPanel = {
            opened: [0],
        };
        this.formValidity = {
            main: false,
            equip: false,
            passport: false,
            employment: false,
            living: false,
            social: false,
            aboard: false,
            familyStatus: false,
            parents: false,
            sportsman: false,
            coach: false,
            judge: false,
        };
        this.formTitle = {
            main: "Основная информация",
            equip: "Экипировка",
            passport: "Паспорт",
            employment: "Образование / Занятость",
            living: "Место проживания",
            social: "Социальные службы / МЕДСТРАХ",
            aboard: "Выезды за рубеж",
            familyStatus: "Семейное положение",
            parents: "Родители",
            sportsman: "Данные спортсмена",
            coach: "Данные тренера",
            judge: "Данные судьи",
        };
        this.commentary = {
            ref: "commentary",
            type: "input",
            label: "Комментарий",
            value: "",
            placeholder: "",
            showOnlyForSuperAuth: false,
            show: true,
        };
        this.uploadedDocuments = [];
        this.documentsUpload = {
            ref: "docs",
            type: "fileupload",
            placeholder: "",
            value: [],
            accept: "image/*,application/*",
            onChange: async (files) => {
                if (files === null || files.length === 0) {
                    this.uploadedDocuments.splice(0, this.uploadedDocuments.length);
                    return;
                }
                let arrayOfFiles = [];
                if (Array.isArray(files) === false) {
                    arrayOfFiles = [files];
                }
                else {
                    arrayOfFiles = files;
                }
                console.log(arrayOfFiles);
                for (let file of arrayOfFiles) {
                    this.uploadedDocuments.push(file);
                }
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
    }
    get user() {
        return this.$store.getters[UserModule.types.getters.USER];
    }
    async addDocuments() {
        document.getElementById("documentsUpload").click();
    }
    checkoutFormsValidity() {
        const forms = Object.values(this.$refs);
        for (const form of forms) {
            const formEl = form;
            if (!formEl.pushChangeEvent)
                continue;
            formEl.pushChangeEvent();
        }
    }
    fillMockAllForms() {
        for (const ref in this.$refs) {
            if (ref === "main")
                continue;
            const form = this.$refs[ref];
            if (!form.setTestFormData)
                continue;
            form.setTestFormData();
        }
    }
    onFormChanged(event) {
        this.updateRequestRaw[event.type] = event.payload;
        this.formValidity[event.type] = event.isValid;
        // console.log(this.updateRequestRaw)
        // console.log(`<${event.type}> isValid: ${this.formValidity[event.type]}`)
    }
    async sendUpdateRequest() {
        this.checkoutFormsValidity();
        await Waiter.wait(1000);
        for (const form in this.formValidity) {
            const formName = form;
            if (this.formValidity[formName])
                continue;
            return this.$notify({
                title: `Форма "${this.formTitle[formName]}" заполнена с ошибками`,
                type: "error",
                duration: 3000,
            });
        }
        const requestDto = new UserUpdateRequestDTO(this.updateRequestRaw, this.commentary.value ? this.commentary.value : null, []);
        const userDto = UserUpdateRequestDTO.createFromUser(this.user);
        // console.log(requestDto.request)
        // console.log(userDto.request)
        const diff = userDto.getDifferenceTo(requestDto);
        console.log(diff);
        if (Object.keys(diff).length === 0) {
            return this.$notify({
                title: "Не обнаружено изменений в данных",
                text: "Для успешной отравки заявки - внесите изменения",
                type: "warning",
                duration: 3000,
            });
        }
        if (this.uploadedDocuments.length > 0) {
            let docs = [];
            const fd = new FormData();
            for (let doc of this.uploadedDocuments) {
                fd.append("docs", doc);
            }
            const response = await this.$store.dispatch(HandbookModule.types.actions.UPLOAD_DOCUMENTS, { fd });
            docs = response;
            requestDto.documents.push(...docs.map((d) => d.src));
        }
        await this.$store.dispatch(UserModule.types.actions.UPDATE_REQUEST, { request: requestDto });
        this.$notify({
            title: "Заявка на обновление профиля успешно отправлена!",
            type: "success",
            duration: 3000,
        });
    }
    mounted() {
        // @ts-ignore
        window.checkoutFormsValidity = this.checkoutFormsValidity;
        // @ts-ignore
        window.fillMockAllForms = this.fillMockAllForms;
    }
};
UserProfile = __decorate([
    Component({
        components: {
            BasicInfoForm,
            EquipForm,
            PassportForm,
            EmploymentForm,
            LivingForm,
            SocialForm,
            AboardForm,
            FamilyStatusForm,
            ParentsForm,
            SportsmanForm,
            CoachForm,
            JudgeForm,
        },
    })
], UserProfile);
export default UserProfile;
